html {
    margin: 0px;
    height: 100%;
    width: 100%;

    body {
        margin: 0px;
        min-height: 100%;
        width: 100%;
        box-sizing: border-box;
        background: #fff;

        .input-group {
            &.borderless{
                .input-group-text{
                    background: transparent;
                    &:nth-child(1){
                        border-right: None;
                    }
                    &:not(:nth-child(1)){
                        border-left: None;
                    }
                }
                input {                    
                    &:nth-child(1){
                        border-right: None;
                    }
                    &:not(:nth-child(1)){
                        border-left: None;
                    }
                }
            }
        }
        .login-dialog {
            width: 400px;
        }
        .content-body {
            padding: 1em 0;
        }
        .zone-container {
            padding: 1em 0;
            .zone-item {
                padding: 0.25em!important;
                .card{
                    .card-body{
                        padding: .5em;
                        p{
                            padding: 0;
                            margin: 0;
                        }
                    }
                    border: 1px solid hsl(0, 0%, 70%);
                    &:hover {
                        border: 1px solid hsl(0, 0%, 40%);
                        cursor: pointer;
                    }
                }
            }
        }
        .navbar {
            border-bottom: solid 1px #284b63;
            background: #396c8f;
            font-weight: 400;
            padding: 1em 0;
            justify-content: center !important;
            .container {
                width: 100%!important;
                .navbar-brand {
                    color: #fff;
                    font-weight: 700;
                }
                .navbar-toggler {
                    border-color: #fff9;
                    .navbar-toggler-icon {
                        filter: invert(1);
                    }
                }
                
                .navbar-nav {
                    flex: 1;
                    justify-content: end;
                    .nav-item {
                        box-sizing: border-box;
                        .nav-link {
                            font-weight: 500;
                            color: #ddd;
                            border-bottom: solid 2px transparent;
                            &:hover {
                                font-weight: 600;
                                color: #fff;
                                @media (min-width: 768px){
                                    border-color: #fff;
                                }
                            }
                        }
                    }
                }
            }
            
        }
        .form-group{
            position: relative;
            .form-label {
                font-size: .8em;
                left: 1em;
                color: #7d90b3;
                font-weight: bolder;
            }
            .form-control {                    
                padding: 0.5em 1em;
                border-color: #ddd;
                font-size: 1em;
                &:focus {
                    color: #212529;
                    background-color: #d9d9d9;
                    border-color: #e6b41e;
                    outline: 0;
                    box-shadow: 0 0 0 0.25rem #e6b41e69;
                }
            }
        }
        input.yellow[type=checkbox] {
            accent-color: #ffc107;
        }
        .btn.btn-yellow{
            background: #ffc107;
            padding: 0.5em 0;
            margin-top: 1em;
            width: 100%;
            color: #000;
            &:hover {
                background: #e6b41e;
                color: #fff;
            }
            &:focus {
                background: #e3b631;
                color: #fff;
            }
            &:disabled {
                opacity: .3;
            }
            &[disabled] {
                opacity: .3;
            }
        }
    }
}